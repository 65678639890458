/* global Common,$,Const */
/**
 * @file 画面共通処理
 * @author Ozaki Takao
 */
(function () {
  'use select';
  if (typeof window.Common === 'undefined') {
    window.Common = {};
  }
  /**
   * モーダル表示
   *
   * @access public
   * @param {string} html モーダル表示内容(HTML)
   * @param {function} funcOnOpen モーダル：オープン時処理
   * @param {function} funcOnClose モーダル：クローズ時処理
   */
  Common.openModal = function (html, funcOnOpen, funcOnClose) {
    $.magnificPopup.open({
      items: {
        type: 'inline',
        src: html
      },
      callbacks: {
        open: funcOnOpen,
        close: funcOnClose
      },
      closeOnBgClick: false,
      closeBtnInside: false,
      midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });
  };
  /**
   * モーダル非表示
   *
   * @access public
   */
  Common.closeModal = function () {
    $.magnificPopup.close();
  };
  // ローディング名称リスト
  // ※重複表示抑止のため、表示で名称を追加登録して、非表示で登録名称から削除する。削除後、件数０で非表示とする。
  var loadingNames = [];
  var LOADING_NAME_DEFAULT = 'common-loading-name-default';
  /**
   * ローディング表示
   *
   * @access public
   * @param {string} name 表示するローディング名称
   */
  Common.showLoading = function (name) {
    // ローディング名称リストに指定名称を追加
    var lName = (typeof name === 'string' && name.length > 0) ? name : LOADING_NAME_DEFAULT;
    var index = loadingNames.indexOf(lName);
    index < 0 && loadingNames.push(lName);
    if ($('#loading').length > 0) {
      // 重複表示を抑止
      return;
    }
    var html = `
      <div id="loading">
      <style>
        #loading {
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: 2000;
          background-color: #0b0b0b;
          opacity: 0.8;
          background-image: url(/common/img/ViewAttachmentImage.gif);
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: fixed;
        }
      </style>
      </div>
    `;
    $('body').append(html);
  };
  /**
   * ローディング非表示
   * ※nameを指定した場合、すべての登録名称０件でローディング非表示
   * @access public
   * @param {string} name 非表示対象のローディング名称
   */
  Common.removeLoading = function (name) {
    // ローディング名称リストから、指定名称を削除
    var lName = (typeof name === 'string' && name.length > 0) ? name : LOADING_NAME_DEFAULT;
    var index = loadingNames.indexOf(lName);
    // ローディング名称リスト０件の場合、非表示
    index >= 0 && loadingNames.splice(index, 1);
    if (loadingNames.length === 0) {
      $('#loading').remove();
    }
  };
  /**
   * タイムピッカーを設定する
   *
   * @access public
   * @param {object} $InputEle jQuery: Inputエレメント
   * @param {string} formatType 日付フォーマットタイプ(デフォルト：YYYY/MM/dd HH:mm:ss)
   * @param {string} minDate 選択可能な最小日付(上記で指定したフォーマットで記述。ただし、「当日以降」の場合は'today')
   */
  Common.setTimePicker = function ($InputEle, formatType, minDate) {
    if (!$InputEle) {
      return;
    }
    var dateFormat;
    var enableSeconds = false;
    switch (formatType) {
      case Const.DATE_TIME_FORMAT.YMD_HMS:
        dateFormat = 'Y/m/d H:i:S';
        enableSeconds = true;
        break;
      case Const.DATE_TIME_FORMAT.YMD_HM:
        dateFormat = 'Y/m/d H:i';
        break;
      case Const.DATE_TIME_FORMAT.YMD:
        dateFormat = 'Y/m/d';
        break;
      default:
        dateFormat = 'Y/m/d H:i:S';
        break;
    }
    var flatpickrConfig = {
      enableTime: true,
      enableSeconds: enableSeconds,
      minuteIncrement: 10,
      dateFormat: dateFormat,
      time_24hr: true,
      locale: 'ja'
    };
    if (minDate) {
      flatpickrConfig.minDate = minDate;
    }
    $InputEle.flatpickr(flatpickrConfig);
  };
  /**
   * 「もっと見る」ボタンのエレメントを取得
   *
   * @access public
   * @param {function} onClickedFunc 「もっと見る」押下時処理
   * @return {object} ボタン表示領域のjQueryエレメント
   */
  Common.getSeeMoreButton = function (onClickedFunc) {
    var showMoreButtonText = `
      <div class="wrap-btn-01 s-ta-c">
        <a class="btn-cmn-03" name="show-more">もっと見る</a>
      </div>
    `;
    var $retEle = $(showMoreButtonText);
    var $showMoreButtonEle = $retEle.find('[name="show-more"]')
    if (typeof onClickedFunc === 'function') {
      $showMoreButtonEle.on('click', onClickedFunc);
    }
    return $retEle;
  };
  /**
   * セレクト用オプション作成の追加
   * @access public
   * @param {string|integer} value オプションのvalue
   * @param {string} text オプションのtext
   * @param {string|integer} selectedValue 選択済みとするオプションのvalue
   * @return {Element} オプションエレメント
   */
  Common.createOptEle = function (value, text, selectedValue) {
    var optionEle = document.createElement('option');
    optionEle.value = value;
    optionEle.textContent = text;
    if (typeof selectedValue !== 'undefined' && selectedValue !== null && selectedValue === value) {
      optionEle.selected = true;
    }
    return optionEle;
  };
  /**
   * ダウンロード処理
   * @access public
   * @param {string} url ダウンロードURL
   * @param {string} fileName ダウンロードファイル名
   */
  Common.fileDownload = function (url, fileName) {
    // 使用ブラウザ：IE
    var xhr = new window.XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onloadend = function () {
      var status = xhr.status;
      if (status === 200) {
        if (window.navigator.msSaveBlob) {
          // 使用ブラウザ：IE
          window.navigator.msSaveBlob(xhr.response, fileName);
        } else {
          // 使用ブラウザ：IE以外
          var anchorEle = document.createElement('a');
          var objectURL = window.URL.createObjectURL(xhr.response);
          anchorEle.href = objectURL;
          anchorEle.download = fileName;
          anchorEle.click();
        }
      }
    };
    xhr.send();
  };
}());
